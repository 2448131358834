import React, { useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/module/Hero";

import banner from "../assets/image/inner-page/png/modules/it-module-banner.png";
import Functionalities from "../sections/module/Functionalities";
import {
  BenefitData,
  FeatureData,
  FunctionalitiesData,
} from "../const/static-data";
import Features from "../sections/module/Features";
import Benefits from "../sections/module/Benefits";
import SectionTabs from "../sections/module/SectionTabs";
import ModuleImage from "../sections/module/ModuleImage";

const ItModule = () => {
  const scrollToSection = (sectionId, offset) => {
    const section = document.getElementById(sectionId);

    if (section) {
      // Scroll to the top of the section
      section.scrollIntoView();

      // Adjust the scroll position by subtracting the offset from the top
      const scrolledY = window.scrollY;
      window.scroll(0, scrolledY + offset);
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const withoutSlashesPath = currentPath.replace(/^\/+|\/+$/g, "").trim();
    const newPath = `/${withoutSlashesPath}/index.html`;
    window.history.replaceState({}, "", newPath);
  }, []);

  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button className="btn btn btn-blue-3 header-btn-2 font-size-3 rounded-5">
                Start 14 Days Free Trial
              </button>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero title={"IT"} banner={banner} />

        <SectionTabs scrollToSection={scrollToSection} />

        <section className="mt-15">
          <div className="container">
            <h2 className="font-size-8">About</h2>
            <p id="section2" className="gray-text font-size-6">
              <span className="title">UNICLOUD360</span> has an integrative IT
              portal that ensures that your school's employees and students are
              given the optimum privileges for utilizing technology in their
              day-to-day activities. This portal is mainly focused on equipping
              your IT management team with all the information, tools and
              schedules to ensure that the IT environment of your school is in
              its best state at all times. IT specialists at your school also
              use this portal to help new users (employees of the school) to
              create profiles and gain access to the unlimited features and
              functions of the UNICLOUD360 platform. It is important to note
              that this portal allows IT managers to restrict or take down
              access to retired staff and graduated students after their time
              with your institution is over.
            </p>
          </div>
        </section>
        <Functionalities data={FunctionalitiesData.It} />
        <Features data={FeatureData.It} />
        <ModuleImage />
        <Benefits data={BenefitData.It} />
        {/*<Team />*/}
        {/*<Stats />*/}
      </PageWrapper>
    </>
  );
};
export default ItModule;
